import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h3: "h3",
    li: "li",
    p: "p",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "The subscriber feature houses the logic for answering the age old question of if a user isSubscriber. It does not contain any of the logic for purchasing or renewing subscriber, however it handles any UI for expressing the state of the subscriber subscription."
    }), "\n", _jsx(_components.h3, {
      id: "features",
      children: _jsx(_components.a, {
        href: "#features",
        children: "Features"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "A route with a list of subscriber features & link to subscribe"
      }), "\n", _jsx(_components.li, {
        children: "An upgraded/enabled version of certain features/overlays for Premium users only"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "critical-functions",
      children: _jsx(_components.a, {
        href: "#critical-functions",
        children: "Critical Functions"
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Injecting upgraded LOL postmatch features"
      }), "\n", _jsx(_components.li, {
        children: "Enabling Premium only overlays"
      }), "\n", _jsx(_components.li, {
        children: "Premium tile & modals for managing & activating subscriber subscription"
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "testing",
      children: _jsx(_components.a, {
        href: "#testing",
        children: "Testing"
      })
    }), "\n", _jsx(_components.p, {
      children: "Coupons:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "HALFOFFMONTH - 1/2 off 1st Month"
      }), "\n", _jsx(_components.li, {
        children: "HALF3MONTHS - 1/2 off for 3 Months"
      }), "\n", _jsx(_components.li, {
        children: "FREEFOREVER - 100% off forever"
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
